.main-navigation {
    border-top: 8px solid $secondary;
    padding-top: 0;
    padding-bottom: 0;
    position: sticky;
    top: 0;
    z-index: 999;

    @include media-breakpoint-up(lg) {
        position: relative;
        top: auto;
    }

    .navbar-toggler {
        width: 25px;
        height: 25px;
        position: relative;
        transform: rotate(0deg);
        cursor: pointer;
        margin-top: 4px;

        &:focus {
            box-shadow: none;
        }
          
        span {
            border-radius: 2px;
            display: block;
            position: absolute;
            height: 2px;
            width: 100%;
            background: $primary;
            opacity: 1;
            left: 0;
            transform: rotate(0deg);
            transition: .25s ease-in-out;
        }
          
        span:nth-child(1) { top: 0px; }
        span:nth-child(2), span:nth-child(3) { top: 10px; }
        span:nth-child(4) { top: 20px; }
          
        &[aria-expanded="true"] {
            span:nth-child(1) {
                top: 10px;
                width: 0%;
                left: 50%;
            }
            span:nth-child(2) { transform: rotate(45deg); }
            span:nth-child(3) { transform: rotate(-45deg); }
            span:nth-child(4) {
                top: 10px;
                width: 0%;
                left: 50%;
            }
        }
    }

    .navbar-nav {
        @include media-breakpoint-up(lg) {
            height: 7rem;
        }
    }

    .navbar-brand {
        padding-left: 1rem;
        margin-right: 2rem;

        .logo {
            width: 7rem;
            padding: 0.5rem 0;
            
            @include media-breakpoint-up(lg) {
                padding: 0;
            }
        }
    }

    .nav-item {
        @include media-breakpoint-up(lg) {
            border-top: 8px solid #d4cfc5;
            margin-top: -8px;
            padding-top: 0;
            padding-bottom: 0;
        }

        flex: 1 auto;
        padding-top: $spacer * 4;
        padding-bottom: $spacer * 4;
        transition: border-color .25s ease-in-out;

        &.active,
        &:active,
        &:focus,
        &:hover {
            border-top-color: $primary;

            .nav-link {
                @include media-breakpoint-up(lg) {
                    border-left-color: $light;
                    border-right-color: $light;
                }
            }
        }
    }

    .nav-link {
        width: 100%;
        height: 100%;
        border-left: 1px solid transparent;
        border-right: 1px solid transparent;
        transition: color .25s ease-in-out;

        &.active {
            font-weight: bold;

            @include media-breakpoint-up(lg) {
                font-weight: 200;
            }
        }
    }
}
