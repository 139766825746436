//increase the left-right padding on mobile container
.container {
    @include media-breakpoint-down(sm) {
        padding-left: $spacer * 6;
        padding-right: $spacer * 6;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.horizontal-scroll-snap {
    display: flex;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    margin: 1rem;
    position: relative;

    @include media-breakpoint-up(lg) {
        flex-wrap: wrap;
        overflow: auto;
        scroll-snap-type: none;
        margin-top: 0;
        margin-left: 0;
    }

    &::-webkit-scrollbar {
        display: none;
    }

    .scroll-snap-item {
        flex-shrink: 0;
        margin-right: $spacer * 4;
        scroll-snap-align: start;
        padding: $spacer * 2 $spacer * 4;
        background-color: $light;

        @include media-breakpoint-up(lg) {
            width: 100%;
            margin-right: 0;
            padding: 0;
            margin-bottom: $spacer * 4;
            background: transparent;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.bottom-content {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background-color: $white;
    padding: 1.5rem;
    border-top: 1px solid $light;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include media-breakpoint-up(lg) {
        position: relative;
        width: 100%;
        border-top: none;
        padding: 0;
        background-color: transparent;
    }
}

/* scroll helper arrow */
.scroll-indicator {
    height: 18px; 

    .arrow-left {
        left: 0;
        border-left: 3px solid #f2f2f2;
        border-bottom: 3px solid #f2f2f2;
        height: 15px;
        width: 15px;
        transform: rotate(45deg);
    }
    .arrow-line {
        background-color: #f2f2f2;
        height: 3px;
        width: 100%;
    }
    .arrow-text {
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        color: #c2c2c2;
    }
    .arrow-right {
        right: 0;
        border-right: 3px solid #f2f2f2;
        border-bottom: 3px solid #f2f2f2;
        height: 15px;
        width: 15px;
        transform: rotate(-45deg);
    }
}

.time-selection {
    cursor: pointer;
    padding: 0.5rem 1rem;
    border: 1px solid var(--bs-light);
    background-color: var(--bs-light);
    color: var(--bs-primary);

    &.active {
        background-color: var(--bs-primary);
        border-color: var(--bs-primary);
        color: var(--bs-white);
    }
}

// cookies accordion style
.accordion .accordion-icon { 
    transform: rotate(0deg);
}
.accordion [aria-expanded='true'] .accordion-icon { 
    transform: rotate(90deg); 
}