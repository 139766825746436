// https://css-tricks.com/snippets/css/using-font-face/

// WOFF2
// Chrome 36+ | Safari NO | Firefox 35+ (with flag) | Opera 23+ | IE NO | Android 37 | iOS NO

// WOFF2 & WOFF
// Chrome 5+ | Safari 5.1+ | Firefox 3.6+ | Opera 11.50+ | IE 9+ | Android 4.4+ | iOS 5.1+

// WOFF2 & WOFF & TTF
// Chrome 3.5+ | Safari 3+ | Firefox 3.5+ | Opera 10.1+ | IE 9+ | Android 2.2+ | iOS 4.3+

//@font-face {
//    font-family: 'Open Sans';
//    src: url('/fonts/OpenSans/font.eot');                                         // IE9 Compat Modes
//    src: local('Open Sans Regular'), local('OpenSans-Regular'),                   // Check if font already installed on machine
//         url('/fonts/OpenSans/font.eot?#iefix')       format('embedded-opentype'), // IE6-IE8
//         url('/fonts/OpenSans/font.woff2')           format('woff2'),             // Super Modern Browsers
//         url('/fonts/OpenSans/font.woff')            format('woff');              // Pretty Modern Browsers
//         url('/fonts/OpenSans/font.ttf')             format('truetype'),          // Safari, Android, iOS
//         url('/fonts/OpenSans/font.svg#svgFontName') format('svg');               // Legacy iOS
//    font-weight: bold;
//}

// When changing (adding or removing fonts) webpack watcher has to be restarted. BUG IN WEBPACK!!
// @font-face {
//     font-family: 'Open Sans';
//     src: url('../../fonts/OpenSans/OpenSans-Light.eot');
//     src: local('Open Sans Light'), local('OpenSans-Light'),
//          url('../../fonts/OpenSans/OpenSans-Light.eot?#iefix') format('embedded-opentype'),
//          url('../../fonts/OpenSans/OpenSans-Light.woff2') format('woff2'),
//          url('../../fonts/OpenSans/OpenSans-Light.woff') format('woff'),
//          url('../../fonts/OpenSans/OpenSans-Light.ttf') format('truetype'),
//          url('../../fonts/OpenSans/OpenSans-Light.svg#OpenSans-Light') format('svg');
//     font-weight: 300;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Open Sans';
//     src: url('../../fonts/OpenSans/OpenSans-LightItalic.eot');
//     src: local('Open Sans Light Italic'), local('OpenSans-LightItalic'),
//          url('../../fonts/OpenSans/OpenSans-LightItalic.eot?#iefix') format('embedded-opentype'),
//          url('../../fonts/OpenSans/OpenSans-LightItalic.woff2') format('woff2'),
//          url('../../fonts/OpenSans/OpenSans-LightItalic.woff') format('woff'),
//          url('../../fonts/OpenSans/OpenSans-LightItalic.ttf') format('truetype'),
//          url('../../fonts/OpenSans/OpenSans-LightItalic.svg#OpenSans-LightItalic') format('svg');
//     font-weight: 300;
//     font-style: italic;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Open Sans';
//     src: url('../../fonts/OpenSans/OpenSans-Regular.eot');
//     src: local('Open Sans Regular'), local('OpenSans-Regular'),
//          url('../../fonts/OpenSans/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
//          url('../../fonts/OpenSans/OpenSans-Regular.woff2') format('woff2'),
//          url('../../fonts/OpenSans/OpenSans-Regular.woff') format('woff'),
//          url('../../fonts/OpenSans/OpenSans-Regular.ttf') format('truetype'),
//          url('../../fonts/OpenSans/OpenSans-Regular.svg#OpenSans-Regular') format('svg');
//     font-weight: normal;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Open Sans';
//     src: url('../../fonts/OpenSans/OpenSans-Italic.eot');
//     src: local('Open Sans Italic'), local('OpenSans-Italic'),
//          url('../../fonts/OpenSans/OpenSans-Italic.eot?#iefix') format('embedded-opentype'),
//          url('../../fonts/OpenSans/OpenSans-Italic.woff2') format('woff2'),
//          url('../../fonts/OpenSans/OpenSans-Italic.woff') format('woff'),
//          url('../../fonts/OpenSans/OpenSans-Italic.ttf') format('truetype'),
//          url('../../fonts/OpenSans/OpenSans-Italic.svg#OpenSans-Italic') format('svg');
//     font-weight: normal;
//     font-style: italic;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Open Sans';
//     src: url('../../fonts/OpenSans/OpenSans-SemiBold.eot');
//     src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
//          url('../../fonts/OpenSans/OpenSans-SemiBold.eot?#iefix') format('embedded-opentype'),
//          url('../../fonts/OpenSans/OpenSans-SemiBold.woff2') format('woff2'),
//          url('../../fonts/OpenSans/OpenSans-SemiBold.woff') format('woff'),
//          url('../../fonts/OpenSans/OpenSans-SemiBold.ttf') format('truetype'),
//          url('../../fonts/OpenSans/OpenSans-SemiBold.svg#OpenSans-SemiBold') format('svg');
//     font-weight: 600;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Open Sans';
//     src: url('../../fonts/OpenSans/OpenSans-SemiBoldItalic.eot');
//     src: local('Open Sans SemiBold Italic'), local('OpenSans-SemiBoldItalic'),
//          url('../../fonts/OpenSans/OpenSans-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
//          url('../../fonts/OpenSans/OpenSans-SemiBoldItalic.woff2') format('woff2'),
//          url('../../fonts/OpenSans/OpenSans-SemiBoldItalic.woff') format('woff'),
//          url('../../fonts/OpenSans/OpenSans-SemiBoldItalic.ttf') format('truetype'),
//          url('../../fonts/OpenSans/OpenSans-SemiBoldItalic.svg#OpenSans-SemiBoldItalic') format('svg');
//     font-weight: 600;
//     font-style: italic;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Open Sans';
//     src: url('../../fonts/OpenSans/OpenSans-Bold.eot');
//     src: local('Open Sans Bold'), local('OpenSans-Bold'),
//          url('../../fonts/OpenSans/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
//          url('../../fonts/OpenSans/OpenSans-Bold.woff2') format('woff2'),
//          url('../../fonts/OpenSans/OpenSans-Bold.woff') format('woff'),
//          url('../../fonts/OpenSans/OpenSans-Bold.ttf') format('truetype'),
//          url('../../fonts/OpenSans/OpenSans-Bold.svg#OpenSans-Bold') format('svg');
//     font-weight: bold;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Open Sans';
//     src: url('../../fonts/OpenSans/OpenSans-BoldItalic.eot');
//     src: local('Open Sans Bold Italic'), local('OpenSans-BoldItalic'),
//          url('../../fonts/OpenSans/OpenSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
//          url('../../fonts/OpenSans/OpenSans-BoldItalic.woff2') format('woff2'),
//          url('../../fonts/OpenSans/OpenSans-BoldItalic.woff') format('woff'),
//          url('../../fonts/OpenSans/OpenSans-BoldItalic.ttf') format('truetype'),
//          url('../../fonts/OpenSans/OpenSans-BoldItalic.svg#OpenSans-BoldItalic') format('svg');
//     font-weight: bold;
//     font-style: italic;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Open Sans';
//     src: url('../../fonts/OpenSans/OpenSans-ExtraBold.eot');
//     src: local('Open Sans ExtraBold'), local('OpenSans-ExtraBold'),
//          url('../../fonts/OpenSans/OpenSans-ExtraBold.eot?#iefix') format('embedded-opentype'),
//          url('../../fonts/OpenSans/OpenSans-ExtraBold.woff2') format('woff2'),
//          url('../../fonts/OpenSans/OpenSans-ExtraBold.woff') format('woff'),
//          url('../../fonts/OpenSans/OpenSans-ExtraBold.ttf') format('truetype'),
//          url('../../fonts/OpenSans/OpenSans-ExtraBold.svg#OpenSans-ExtraBold') format('svg');
//     font-weight: 800;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Open Sans';
//     src: url('../../fonts/OpenSans/OpenSans-ExtraBoldItalic.eot');
//     src: local('Open Sans ExtraBold Italic'), local('OpenSans-ExtraBoldItalic'),
//          url('../../fonts/OpenSans/OpenSans-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
//          url('../../fonts/OpenSans/OpenSans-ExtraBoldItalic.woff2') format('woff2'),
//          url('../../fonts/OpenSans/OpenSans-ExtraBoldItalic.woff') format('woff'),
//          url('../../fonts/OpenSans/OpenSans-ExtraBoldItalic.ttf') format('truetype'),
//          url('../../fonts/OpenSans/OpenSans-ExtraBoldItalic.svg#OpenSans-ExtraBoldItalic') format('svg');
//     font-weight: 800;
//     font-style: italic;
//     font-display: swap;
// }


// --> included adobe fonts kit in _layout
// body / regular text font
body {
    font-family: 'nobel', sans-serif;
    font-weight: 200;
    font-style: normal;
    font-size: 20px;
}

// titles font
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'playfair-display', serif;
    font-weight: 700;
    font-style: normal;
}

h1 {
    @extend .fs-2;
    @extend .mb-4;
}

h2 {
    @extend .fs-3;
    @extend .mb-3;
}

h3 {
    @extend .fs-4;
    @extend .mb-2;
}

.lead {
    @extend .fs-4;
    @extend .lh-1;
    @extend .my-6;
}

// fonts utility classes
.font-serif {
    font-family: playfair-display, serif;
    font-weight: 700;
    font-style: normal;
}
.font-sans-serif {
    font-family: nobel, sans-serif;
    font-weight: 200;
    font-style: normal;
}