@use 'sass:map';

// Variables
@use './utils/svg';

// Color system
$primary:   hsl(73, 5%, 34%);
$secondary: hsl(40, 15%, 80%);
$success:   hsl(119, 70%, 38%);
$info:      hsl(204, 70%, 58%);
$warning:   hsl(40, 100%, 45%);
$danger:    hsl(8, 92%, 43%);
$light:     hsl(33, 27%, 94%);
$dark:      hsl(206, 11%, 13%);
$lightgray: hsl(0, 0%, 94%);

:root {
    --primary:   #{$primary};
    --secondary: #{$secondary};
    --success:   #{$success};
    --info:      #{$info};
    --warning:   #{$warning};
    --danger:    #{$danger};
    --light:     #{$light};
    --dark:      #{$dark};
}

$theme-colors: (
    'primary':   $primary,
    'secondary': $secondary,
    'success':   $success,
    'info':      $info,
    'warning':   $warning,
    'danger':    $danger,
    'light':     $light,
    'dark':      $dark
);

// Auto calculation contrast ratio setting
$min-contrast-ratio:   4.5;

// Add custom colors to theme-colors
$custom-theme-colors: (
    'brand':        $primary,
    'brand-dark':   $dark,
    'brand-light':  $light,
);

// Save as array
$theme-colors: map.merge($theme-colors, $custom-theme-colors);
$theme-colors-rgb: map-loop($theme-colors, to-rgb, '$value');

// Spacings
$spacer: 0.25rem;
$spacers: (
    0:  0,
    1:  $spacer * 1,
    2:  $spacer * 2,
    3:  $spacer * 3,
    4:  $spacer * 4,
    5:  $spacer * 5,
    6:  $spacer * 6,
    7:  $spacer * 7,
    8:  $spacer * 8,
    9:  $spacer * 9,
    10: $spacer * 10,
    11: $spacer * 11,
    12: $spacer * 12,
    14: $spacer * 14,
    16: $spacer * 16,
    20: $spacer * 20,
    24: $spacer * 24,
    26: $spacer * 26,
    28: $spacer * 28,
    32: $spacer * 32,
    36: $spacer * 36,
    40: $spacer * 40,
    44: $spacer * 44,
    48: $spacer * 48,
    52: $spacer * 52,
    56: $spacer * 56,
    60: $spacer * 60,
    64: $spacer * 64,
    72: $spacer * 72,
    80: $spacer * 80,
    96: $spacer * 96,
);

// Fix spacings
$blockquote-margin-y:       map.get($spacers, 4);
$hr-margin-y:               map.get($spacers, 4);
$dropdown-item-padding-y:   map.get($spacers, 1);
$dropdown-item-padding-x:   map.get($spacers, 4);
$card-spacer-y:             map.get($spacers, 4);
$card-spacer-x:             map.get($spacers, 4);
$card-img-overlay-padding:  map.get($spacers, 4);
$popover-header-padding-x:  map.get($spacers, 4);
$popover-body-padding-y:    map.get($spacers, 4);
$popover-body-padding-x:    map.get($spacers, 4);
$modal-inner-padding:       map.get($spacers, 4);
$alert-padding-y:           map.get($spacers, 4);
$alert-padding-x:           map.get($spacers, 4);
$list-group-item-padding-y: map.get($spacers, 2);
$list-group-item-padding-x: map.get($spacers, 4);

// Font sizes (Reset). Use "fs-X" to set font size!
$font-size-base: 1rem;
$h1-font-size: $font-size-base;
$h2-font-size: $font-size-base;
$h3-font-size: $font-size-base;
$h4-font-size: $font-size-base;
$h5-font-size: $font-size-base;
$h6-font-size: $font-size-base;

// General settings
$enable-rounded: false;
$enable-negative-margins: true;

// Button
$btn-font-weight: 200;

// z-index fixed elements
$zindex-fixed: 9999;

// Accordion
$accordion-button-icon:         svg.svg-url("<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-plus' viewBox='0 0 16 16'><path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z'/></svg>");
$accordion-button-active-icon:  svg.svg-url("<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-dash' viewBox='0 0 16 16'><path d='M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z'/></svg>");

// Modal
$modal-backdrop-opacity: .75;

// Button
$btn-font-size: $font-size-base * 1.25;

// Form
$input-padding-y:       $spacer * 3;
$input-padding-x:       $spacer * 3;
$input-font-size:       $font-size-base * 1.25;
$input-font-weight:     200;
$input-bg:              $light;
