/* override stylings with fui variables */
:root {
    --fui-font: $font-family-base;
    --fui-color: #000;
    --fui-primary-color: hsl(73, 5%, 34%);
    --fui-error: hsl(8, 92%, 43%);
    --fui-border-radius: 0px;
    --fui-check-check-border-radius: 0px;
    --fui-focus-shadow: 0 0 0 3px hsla(73, 5%, 60%, 0.45);
    --fui-focus-border-color: hsl(73, 5%, 60%);
    --fui-input-background-color: hsl(33, 27%, 94%);
    
    //font sizes
    --fui-label-font-size: 1.35rem;
    --fui-input-font-size: 1.25rem;
    --fui-check-font-size: 1.25rem;
    --fui-alert-font-size: 1.25rem;
    --fui-error-font-size: 1.25rem;    
}

.fui-form {
    .fui-input, .fui-select {
        padding: $spacer * 4;
        font-weight: 200;
    }

    .fui-btn {
        @extend .btn;
        @extend .btn-lg;
    }
    .fui-submit {
        @extend .btn-primary;
    }
}